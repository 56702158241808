import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title="ChatGPT Voice"
        titleTemplate="%s"
        defaultTitle="ChatGPT: Get instant answers, find inspiration, learn something new"
        description="ChatGPT helps you get answers, find inspiration and be more productive. It is free to use and easy to try. Just ask and ChatGPT can help with writing, learning, brainstorming and more."
        openGraph={{
          url: 'https://oaifree-voice.775825.xyz',
          images: [
            {
              url: 'https://oaifree-cdn.775825.xyz/_next/static/media/chatgpt-share-og.49cbbffe.png',
            },
          ],
          site_name: 'ChatGPT Voice',
        }}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: '#070707',
          },
        ]}
        additionalLinkTags={[
          {
            rel: 'icon',
            href: 'https://oaifree-cdn.775825.xyz/_next/static/media/favicon-32x32.630a2b99.png',
            sizes: '32x32',
          },
          {
            rel: 'apple-touch-icon',
            href: 'https://oaifree-cdn.775825.xyz/_next/static/media/apple-touch-icon.82af6fe1.png',
            sizes: '180x180',
          },
        ]}
      />
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
